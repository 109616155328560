






















import { Vue, Component, Prop } from "vue-property-decorator";
import { Input } from "element-ui";
@Component({
    components: {
        "el-input": Input
    }
})
export default class FormItemInput extends Vue {
    @Prop({
        type: [String, Number, Object],
        required: false,
        default: () => ("")
    })
    value: any; //表单数据

    @Prop({ type: Object, required: true, default: () => {} })
    formItemOpt: any; //表单参数

    val: string = ""; //绑定值

    /**在 Input 值改变时触发，传递给父组件 */
    change(data: any) {
        this.$emit("onchange", data);
    }

    verifyCodeBtn: boolean = false; // “获取验证码”按钮是否disabled
    maxtime: number = 60; //倒计时最大时间
    second: number = 60;
    timer: any;

    /* 获取邮箱验证码 */
    getVerifyCode(email: string) {
        let getData = () => {
            this.$axios({
                url: `${this.$api.apiList.emailCaptha.url}/${email}`,
                method: this.$api.apiList.emailCaptha.method
            })
                .then((res: any) => {
                    // console.log(res.data);
                    this.verifyCodeBtn = true;
                    this.second = 60;
                    this.leftTimer(this.second);

                    if (res.data.code == 0) {
                        this.$notify.error({
                            title: "错误",
                            message: res.data.msg
                        });
                    } else if (res.data.code == 1) {
                        this.$notify.success({
                            title: "成功",
                            message: res.data.msg
                        });
                    } else {
                        this.$notify.warning({
                            title: "警告（未知错误）",
                            message: "未定义的服务器返回值"
                        });
                    }
                })
                .catch((err: any) => {
                    console.log(err.response);
                    this.$notify.error({
                        title: "错误",
                        message: "服务器返回err"
                    });
                });
        };


        // this.$emit(
        //     "valid",
        //     this.formName,
        //     this.formItemOpt.formkey,
        //     (res: any) => {
        //         if (res.state === true) {
        //             getData();
        //         } else {
        //             this.$notify.error({
        //                 title: "错误",
        //                 message: res.msg
        //             });
        //         }
        //     }
        // );
    }

    /* 请求验证码按钮文字 */
    get returnVerifyCodeBtnDetail() {
        //computed name
        // return 'hey ' + this.msg
        if (this.verifyCodeBtn == false) {
            return "请求验证码";
        } else if (this.verifyCodeBtn == true) {
            return `${this.second} s`;
        }
    }

    /* 倒计时 */
    leftTimer(second: any) {
        let seconds = second * 1000;
        this.second = second - 1;
        let that = this;
        this.timer = setTimeout(() => {
            if (this.second > 0) {
                that.leftTimer(this.second);
            } else {
                this.verifyCodeBtn = false;
                clearTimeout(this.timer); /*  */
            }
        }, 1000);
    }

    mounted() {
        
        this.val = this.value;
    }
}
